import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import DashboardLayout from 'layouts/DashboardLayout'
import GuestGuard from 'components/GuestGuard'
import AuthGuard from 'components/AuthGuard'
import LoginLayout from 'layouts/LoginLayout'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: LoginLayout,
    component: lazy(() => import('views/SignIn/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    layout: LoginLayout,
    component: lazy(() => import('views/SignIn/RegisterView/Register'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard/projects',
        component: lazy(() => import('views/UserSummary/UserSummary'))
      },
      {
        exact: true,
        path: '/app/dashboard/projects/detection-sets',
        component: lazy(() => import('views/DetectionSets/DetectionSets'))
      },
      {
        exact: true,
        path: '/app/dashboard/projects/detection-items',
        component: lazy(() => import('views/DetectionItems/DetectionItems'))
      },
      {
        exact: true,
        path: '/app/dashboard/projects/food-label',
        component: lazy(() => import('views/FoodLabel/FoodLabel'))
      },
      {
        exact: true,
        path: '/app/dashboard/projects/food-labelled',
        component: lazy(() => import('views/FoodLabel/LabeledImages'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/dashboard/projects" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard/projects" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]

export default routes
