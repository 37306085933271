import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-quill/dist/quill.snow.css'
import 'prismjs/prism'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-jsx'
import 'nprogress/nprogress.css'
import 'css/prism.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { enableES5 } from 'immer'
import * as serviceWorker from 'serviceWorker'
import createSagaMiddleware from 'redux-saga'
import { SettingsProvider } from 'contexts/SettingsContext'
import App from 'App'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './redux/reducers'
import sagas from './redux/sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const store = createStore(reducers(), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_END_POINT
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

enableES5()

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register()
